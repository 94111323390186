
.table-primary {
  .ant-table {
    color: #333333;
    padding-bottom: 0px!important;

    .ant-table-container::before, .ant-table-container::after {
      box-shadow: none;
    }

    .ant-table-tbody .ant-table-cell {
      vertical-align: top;
    }
  }

  .ant-table-pagination.ant-pagination{
    margin: 16px;
  }
}
.ant-table-thead > tr > th {
  text-transform: uppercase;
  font-size:12px;
}

.ant-table-tbody > tr > td {
  font-size:13px;
}


.table-responsive .ant-table-content .ant-table-tbody .ant-table-cell {
  white-space: normal;
  padding: 6px 8px;
}

.modal-second .ant-modal-content{
  background-color: #F4F5F7;
}

.modal-full{
  max-width: 100%;
  border-radius: 0;
}
.modal-full .ant-modal-body{
  padding: 0px;
}

.ant-select-multiple .ant-select-selection-item {
  line-height: 20px;
  margin-top: 2px;
  margin-bottom: 2px;
  height: inherit!important;
}

.login-bg {
  position: absolute;
  filter: blur(140px);
  z-index: -1;
  inset: 0px;
  overflow: hidden;
}

.login-bg::before {
  content: " ";
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-color: rgb(247, 220, 179);
  position: absolute;
  top: 0px;
  right: 0px;
  opacity: 1;
}

.login-bg-1 {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: rgb(192, 229, 217);
  margin-left: 160px;
  position: absolute;
  bottom: 180px;
  opacity: 1;
}

.login-bg-2 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgb(231, 103, 103);
  position: absolute;
  bottom: 0px;
  left: -50px;
  opacity: 1;
}

.gjs-pn-buttons .gjs-pn-btn{
  font-size: 14px;
}
