body.dark {
  --box_shadow: linear-gradient(180deg, rgba(10, 10, 10, 0) 0%, #0a0a0a 100%);
  --bg_birthday: url('https://static.simplize.vn/static/bg/bg_dark2.png');
  --bg_birthday_slider: url('https://static.simplize.vn/static/bg/birthday_slider_dark.png');
  --birthday_cake: url('https://static.simplize.vn/static/bg/birthday_cake_dark.png');
  --sale_20: url('https://cdn.simplize.vn/simplizevn/community/images/1689158784232-sale-20-last-dark.png');
  --100k_user: url('https://static.simplize.vn/static/bg/100k-user-dark.png');
  --2_9_star: url('https://static.simplize.vn/static/bg/2-9-star.png');
  --vn_flag: url('https://static.simplize.vn/static/bg/vn-flag.png');

  --order_ao: url('https://static.simplize.vn/static/campaign/order-ao.png');
  --order_ao_bg: url('https://static.simplize.vn/static/campaign/order-ao-bg-dark.png');

  --sale_30: url('https://static.simplize.vn/static/image/sale-30-dark.png');
  --sale_30_bg: url('https://static.simplize.vn/static/image/sale-30-bg-dark.png');
  --notice_bg: url('https://static.simplize.vn/static/image/notice-bg-dark.png');

  --sale_40_bg: url('https://static.simplize.vn/static/image/sale-40-slide-dark-bg.png');

  --slide_in: linear-gradient(
    252deg,
    rgba(102, 102, 102, 0.2) 0%,
    rgba(102, 102, 102, 0.05) 100%
  );

  --white_dark100ToBlack100: var(--white_dark100);
  --BlueOpacity5ToBlue100: var(--blue_opacity5);
  --white_dark100ToWhite500: var(--white_dark100);
  --white_dark200ToWhite500: var(--white_dark200);
  --backgroundBlogSecondary: #2d2e2f;
  --black600Towhite500: var(--black600);
  --blue_opacity10ToWhite500: var(--blue_opacity10);
  --blue_opacity10ToBlue_opacity5: var(--blue_opacity10);
  --white_opacity5ToBlack200: var(--white_opacity5);
  --white_opacity5ToBlue100: var(--white_opacity5);
  --gray100ToGray500: var(--gray100);
  --black300ToBlack400: var(--black300);
  --gray300ToGray500: var(--gray300);
  --gray400ToBlue300: var(--gray400);
  --white500ToWhiteDark200: var(--white_dark200);
  --white500ToBlack500: var(--white500);
  --white500ToGray400: var(--white500);
  --white_opacity5ToWhite500: var(--white_opacity5);
  --black300ToBlue300: var(--black300);
  --white500ToBlue500: var(--white500);
  --black500Towhite500: var(--black500);
  --gray300ToGray400: var(--gray300);
  --white_opacity10ToBlue200: var(--white_opacity10);
  --gray200ToGray400: var(--gray200);
  --gray200ToGray500: var(--gray200);
  --white_opacity10ToBlack200: var(--white_opacity10);
  --white_opacity10ToBlack100: var(--white_opacity10);
  --white_opacity10ToBlack_opacity10: var(--white_opacity10);
  --black400ToBlue200: var(--black400);
  --background_darkTobackground_light: var(--background_dark);
  --white_opacity5ToBlack100: var(--white_opacity5);
  --white_opacity5ToBlue_opacity5: var(--white_opacity5);
  --white_opacity10ToWhite500: var(--white_opacity10);
  --white_opacity10ToBlue_opacity5: var(--white_opacity10);
  --white_opacity5ToBlack_opacity10: var(--white_opacity5);
  --whiteDark100ToBlack100: var(--white_dark100);
  --blueOpacity5ToBlue100: var(--blue_opacity5);
  --whiteDark100ToWhite500: var(--white_dark100);
  --whiteDark200ToWhite500: var(--white_dark200);
  --black01ToWhite500: var(--black01);
  --blueOpacity10ToWhite500: var(--blue_opacity10);
  --blueOpacity10ToBlueOpacity5: var(--blue_opacity10);
  --whiteOpacity5ToBlack200: var(--white_opacity5);
  --whiteOpacity5ToBlue100: var(--white_opacity5);
  --whiteOpacity5ToWhite500: var(--white_opacity5);
  --whiteOpacity10ToBlue200: var(--white_opacity10);
  --whiteOpacity10ToBlack200: var(--white_opacity10);
  --whiteOpacity10ToBlack100: var(--white_opacity10);
  --whiteOpacity10ToBlackOpacity10: var(--white_opacity10);
  --backgroundDarkToBackgroundLight: var(--background_dark);
  --whiteOpacity5ToBlack100: var(--white_opacity5);
  --whiteOpacity5ToBlueOpacity5: var(--white_opacity5);
  --whiteOpacity10ToWhite500: var(--white_opacity10);
  --whiteOpacity10ToBlueOpacity5: var(--white_opacity10);
  --whiteOpacity5ToBlackOpacity10: var(--white_opacity5);
  --dc001: var(--background_dark);
  --dc002: var(--black01);
  --dc003: var(--black300);
  --dc004: var(--black300);
  --dc005: var(--black400);
  --dc006: var(--black500);
  --dc007: var(--black600);
  --dc008: var(--blue_opacity5);
  --dc009: var(--blue_opacity10);
  --dc010: var(--blue_opacity10);
  --dc011: var(--gray100);
  --dc012: var(--gray200);
  --dc013: var(--gray200);
  --dc014: var(--gray300);
  --dc015: var(--gray300);
  --dc016: var(--gray400);
  --dc017: var(--white500);
  --dc018: var(--white500);
  --dc019: var(--white500);
  --dc020: var(--black500);
  --dc021: var(--white_dark100);
  --dc022: var(--white_dark100);
  --dc023: var(--white_dark200);
  --dc024: var(--white_opacity5);
  --dc025: var(--white_opacity5);
  --dc026: var(--white_opacity5);
  --dc027: var(--white_opacity5);
  --dc028: var(--white_opacity5);
  --dc029: var(--white_opacity5);
  --dc030: var(--white_opacity10);
  --dc031: var(--white_opacity10);
  --dc032: var(--white_opacity10);
  --dc033: var(--white_opacity10);
  --dc034: var(--white_opacity10);
  --dc035: var(--white_opacity10);
  --dc036: var(--background_light);
  --dc037: var(--black_opacity30);
  --dc038: var(--white_opacity5);
  --dc039: var(--blue_opacity10);
  --dc040: var(--white_opacity10);
  --dc041: var(--white_dark200);
  --dc042: var(--blue_opacity30);
  --dc043: var(--darkTablerHeader);
  --dc044: var(--white_opacity10);
  --dc045: var(--black400);
  --dc046: var(--white_opacity10);
  --dc047: var(--black300);
  --dc048: var(--black200);
  --dc049: var(--white_opacity10);
  --dc050: var(--black300);
  --dc051: var(--white_opacity10);
  --dc052: var(--darkTablerHeader2);
  --dc053: var(--darkOverlay);
  --dc054: var(--gray500);
  --dc055: var(--darkOverlay2);
  --dc056: var(--white_dark200);
  --dc057: var(--black_opacity70);
  --dc058: var(--gray300);
  --dc059: var(--white_opacity10);
  --dc060: var(--gray100);
  --dc061: var(--gray400);
  --dc062: var(--blue_opacity10);
  --dc063: var(--white_dark200);
  --dc064: var(--gray500);
  --dc065: var(--black400);
  --dc066: var(--white_opacity40);
  --dc067: var(--white500);
  --dc068: var(--black400);
  --dc069: var(--darkOverlay3);
  --dc070: var(--black400);
  --dc071: var(--black_opacity70);
  --dc072: var(--black500);
  --dc073: var(--darkOverlay);
  --dc074: var(--black300);
  --dc075: var(--black_opacity70);
}
